<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Manage visitor categories</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Visitor category
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                    @click="__add()"
                    class="mt-4 btn btn-primary"
                    style="color: #fff"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp; Add
                    visitor category
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div
              class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start"
            >
              <div class="row">
                <v-col cols="12" md="5">
                  <v-text-field
                    label="Title"
                    type="text"
                    dense
                    v-on:keyup.enter="__get"
                    outlined
                    v-model="search.title"
                  />
                </v-col>

                <v-col cols="12" md="3">
                  <v-select
                    label="Status"
                    v-on:keyup.enter="__get"
                    outlined
                    dense
                    :items="active_statuses"
                    item-text="title"
                    item-value="value"
                    v-model="search.active"
                    clearable
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="4" class="text-right">
                  <v-btn
                    :loading="loading"
                    @click="__get"
                    class="btn btn-primary btn-search"
                    style="color: #fff"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="">
              <v-skeleton-loader type="table-thead" v-if="loading"> </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                  <tr>
                    <th class="px-3 wrap-column">Title</th>
                    <th class="px-3 wrap-column">Status</th>
                    <th class="px-3 text-center">Action</th>
                  </tr>
                </thead>

                <tr
                  v-show="visitors_catgories.length > 0"
                  v-for="(leave_category, index) of visitors_catgories"
                >
                  <td class="px-3 wrap-column">
                    <a href="#" @click="__edit(leave_category.id)" class="mr-2">
                      {{ leave_category.title }}</a
                    >
                  </td>

                  <td class="px-3 wrap-column pt-3">
                    <span 
                      v-if="leave_category.is_active"
                      class="text-lg badge badge-success">
                        Active
                    </span>

                    <span 
                      v-if="!leave_category.is_active"
                      class="text-lg badge badge-danger" style="background-color: #F64E60 !important;">
                        Inactive
                    </span>
                  </td>
                  <td class="pr-0 text-center">
                    <b-dropdown
                      size="sm"
                      variant="link"
                      toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                      no-caret
                      right
                      no-flip
                    >
                      <template v-slot:button-content>
                        <slot>
                          <span>
                            <i class="flaticon-more-1"></i> </span
                        ></slot>
                      </template>
                      <!--begin::Navigation-->
                      <div class="navi navi-hover">
                        <!--                                                  <b-dropdown-text tag="div" class="navi-item">-->
                        <!--                                                    <a href="#" class="navi-link" @click="detail(leave_category.id)">-->
                        <!--                                                            <span class="navi-icon">-->
                        <!--                                                              <i class="fas fa-eye"></i>-->
                        <!--                                                            </span>-->
                        <!--                                                      <span class="navi-text"> View </span>-->
                        <!--                                                    </a>-->
                        <!--                                                  </b-dropdown-text>-->
                        <b-dropdown-text tag="div" class="navi-item">
                          <a
                            href="#"
                            class="navi-link"
                            @click="__edit(leave_category.id)"
                          >
                            <span class="navi-icon">
                              <i class="flaticon-edit"></i>
                            </span>
                            <span class="navi-text"> Edit </span>
                          </a>
                        </b-dropdown-text>

                        <b-dropdown-text tag="div" class="navi-item">
                          <a
                            href="#"
                            class="navi-link"
                            @click="__delete(leave_category.id)"
                          >
                            <span class="navi-icon">
                              <i class="fas fa-trash"></i>
                            </span>
                            <span class="navi-text"> Delete</span>
                          </a>
                        </b-dropdown-text>
                      </div>
                      <!--end::Navigation-->
                    </b-dropdown>
                  </td>
                </tr>
                <tr v-if="visitors_catgories.length == 0">
                  <td colspan="3" class="text-center">
                    <strong>Data not available</strong>
                  </td>
                </tr>
              </table>
              <b-pagination
                v-if="visitors_catgories.length > 0"
                class="pull-right mt-7"
                @input="__get"
                v-model="page"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
              ></b-pagination>
            </div>
          </div>
          <create-and-update
            ref="leave_category"
            @refresh_visitor_category="__get"
          ></create-and-update>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import CreateAndUpdate from "./CreateAndUpdate";
import VisitorCategoryService from "@/core/services/visitor/visitor-category/VisitorCategoryService";
const visitorCategoryService = new VisitorCategoryService();
export default {
  name: "visitor-category",
  display: "Table",
  order: 8,
  components: {
    CreateAndUpdate,
  },
  data() {
    return {
      active_statuses: [
        { title: "Active", value: "active" },
        { title: "Inactive", value: "in_active" },
      ],
      visitors_catgories: [],
      page: null,
      loading: false,
      perPage: null,
      total: null,
      search: {
        active: "active",
        is_parent: "null",
      },
    };
  },
  mounted() {
    this.__get();
  },
  computed: {},
  methods: {
    getApplicable_users(users) {
      return users.split(",");
    },
    __get() {
      this.loading = true;
      visitorCategoryService
        .paginate(this.search)
        .then((response) => {
          this.visitors_catgories = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          // console.log(error);
        });
    },
    __add() {
      this.$refs["leave_category"].showModal();
    },
    __edit(id) {
      this.$refs["leave_category"].showModal(id);
    },

    __delete(id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            visitorCategoryService
              .delete(id)
              .then((response) => {
                this.$snotify.success("Successfully Deleted");
                this.__get();
              })
              .catch((error) => {
                //console.log(error);
              });
          }
        },
      });
    },
  },
};
</script>
