<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="800">
            <v-card>
              <v-toolbar dark>
                <v-card-title class="headline">
                  {{ `${edit ? 'Update' : 'Add'}` }}  visitor category
                  <hr>
                </v-card-title>

                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-spacer></v-spacer>
                  <v-btn icon  @click="cancelForm">
                    <i class="fas fa-times"></i>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="visitor_category.title" outlined dense>
                                <template v-slot:label>
                                    Title <span class="text-danger">*</span>
                                </template>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.visitor_category.title.$error">This information is required</span>
                        </v-col>
                        <v-col cols="12">
                            <label for="description">Description <span class="text-danger">*</span></label>
                            <ckeditor id="description" v-model="visitor_category.description"
                                      :config="editorConfig"></ckeditor>
                            <span class="text-danger" v-if="$v.visitor_category.description.$error">This information is required</span>
                        </v-col>
                        <v-col cols="3">
                            <v-switch v-model="visitor_category.is_active" label="Active"></v-switch>
                        </v-col>

                        <v-col cols="12">
                            <div class="text-right float-right">
                                <v-btn depressed @click="cancelForm" class="text-gray btn btn-standard cancel-btn">
                                    Cancel
                                </v-btn>
                                <v-btn depressed :loading="isBusy" @click="createOrUpdate"
                                       class="text-white ml-2 btn btn-primary ">
                                    Save
                                </v-btn>
                            </div>

                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>

</template>
<script>
    import {required} from "vuelidate/lib/validators";
    import VisitorCategoryService from "@/core/services/visitor/visitor-category/VisitorCategoryService";
    const visitorCategoryService = new VisitorCategoryService();

    export default {
        name: 'create-visitor-category',
        validations: {
            visitor_category: {
                title: {required},
                description: {required},
            }
        },
        data() {
            return {
                academic_class: [],
                dialog: false,
                edit: false,
                isBusy: false,
                search: {
                    active: "active",
                    is_parent: 1,
                },
                applicable_for: [
                    {
                        name: 'Student',
                        value: 'student'
                    },
                    {
                        name: 'Teachers',
                        value: 'teacher'
                    }, {
                        name: 'Staff',
                        value: 'staff'
                    },
                    {
                        name: 'All',
                        value: 'all'
                    }
                ],
                editorConfig: {
                  versionCheck: false,
                    toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
                },
                visitor_category: {
                    title: null,
                    description: null,
                    applicable_users: null,
                    is_active: true,
                    auto_approval: false
                },
            };
        },
        mounted() {

        },
        methods: {
            cancelForm(){
                this.resetForm()
                this.hideModal()
            },
            showModal(id = null) {
                if (id) {
                    this.edit = true
                    this.__getSingle(id)
                }else{
                    this.resetForm()
                }
                this.dialog = true;
            },
            hideModal() {
                this.dialog = false;
            },
            toggleModal() {
                this.$refs["my-modal"].toggle("#toggle-btn");
            }, __getSingle(id) {
                visitorCategoryService.show(id).then(response => {
                    this.visitor_category = response.data.visitor_category
                })
            },

            createOrUpdate() {

                this.$v.visitor_category.$touch();
                if (this.$v.visitor_category.$error) {
                    setTimeout(() => {
                        this.$v.visitor_category.$reset();
                    }, 3000);
                } else {
                    let fd = this.visitor_category;
                    if (this.edit) {
                        this.__update(fd);
                    } else {
                        this.__create(fd);
                    }
                }
            },
            __update(fd) {
                this.isBusy = true;
                visitorCategoryService
                    .update(this.visitor_category.id, fd)
                    .then(response => {
                        this.isBusy = false;
                        this.$snotify.success("Information updated");
                        this.resetForm();
                        this.hideModal();
                        this.$emit("refresh_visitor_category");
                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                        // console.log(error);
                    });
            },


            __create(fd) {
                this.isBusy = true;
                visitorCategoryService
                    .store(fd)
                    .then((response) => {
                        // console.log(response)
                        this.isBusy = false;
                        this.resetForm();
                        this.hideModal();
                        this.$emit("refresh_visitor_category");
                        this.$snotify.success("Information added");

                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                        // console.log(error);
                    });
            },
            resetForm() {
                this.$v.visitor_category.$reset();
                this.edit = false;
                this.visitor_category = {
                    title: null,
                    description: null,
                    applicable_users: null,
                    is_active: true,
                    auto_approval: false
                };
            }
        }
    };
</script>
